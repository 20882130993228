@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body,
input {
  font-family: "Poppins", sans-serif;
}

:root {
  /* #4800ff in decimal RGB */
  --secondary: rgb(49, 0, 174);
  /* #ff0000 in decimal RGB */
  --main-color: rgb(255, 0, 0);
  /* #ffffff in decimal RGB */
  --white: rgb(255, 255, 255);
  /* #555555 in decimal RGB */
  --grey: rgb(85, 85, 85);
  /* #959595 in decimal RGB */
  --lightgrey: rgb(149, 149, 149);
  /* #222222 in decimal RGB */
  --darkgrey: rgb(34, 34, 34);
  /* #000000 in decimal RGB */
  --black: rgb(0, 0, 0);
  /* #fdfdfc in decimal RGB */
  --light: rgb(253, 253, 252);
  /* #1f9fd4 in decimal RGB */
  --light-secon--secondary: rgb(31, 59, 212);
  /* #ffcc00 in decimal RGB */
  --yellow: rgb(255, 204, 0);
  /* #f5f5f5 in decimal RGB */
  --bg-grey: rgb(245, 245, 245);
  /* #4661c5 in decimal RGB */
  --facebook-color: rgb(70, 97, 197);
  /* #44b1e4 in decimal RGB */
  --twitter-color: rgb(68, 177, 228);
  /* #0029c2 in decimal RGB */
  --linkedin-color: rgb(0, 41, 194);
  /* #122e6d in decimal RGB */
  --ggec-secondary: rgb(18, 46, 109);
  /* #ff8c6b in decimal RGB */
  --ggec-main-color: rgb(255, 140, 107);

  /* #ad0001 in decimal RGB */
  --red: rgb(173, 0, 1);
  /* #033e00 in decimal RGB */
  --green: rgb(3, 62, 0);
  /* #0ec312 in decimal RGB */
  --light-green: rgb(14, 195, 18);
  /* #0043ff in decimal RGB */
  --sl-secon--secondary: rgb(0, 67, 255);
  /* #00118e in decimal RGB */
  --dl-secon--secondary: rgb(0, 17, 142);
  /* #3f003b in decimal RGB */
  --purple: rgb(63, 0, 59);
  /* #be00c5 in decimal RGB */
  --light-purple: rgb(190, 0, 197);
  --hRed: #f52c2c;

  --main-color-opicity: 255, 0, 0;
  --secondary-opicity: 49, 0, 174;
  --white-opicity: 255, 255, 255;
  --black-opicity: 0, 0, 0;
  --sideColor: #ebf6ff;
  --primary-bg: #171717;
  --secondary-bg: #262626;
  --accent-bg: #4f46e5;

  --primary-color: #fff;
  --secondary-color: rgba(255, 255, 255, 70%);
  --accent-color: #818cf8;

  --border-color: rgba(255, 255, 255, 30%);

  --username-size: 32px;
  --title-size: 18px;
  --subtitle: 18px;

  --calendar-main-color: #1a8fff;
  --calendar-text-color: #777;
  --calendar-text-color-light: #ccc;
  --calendar-border-color: #eee;
  --calendar-bg-color: #f9f9f9;
  --calendar-neutral-color: #fff;
}

main {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  padding: 2rem;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-image: url("../../images/ray_taxi.webp") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  position: relative;
  z-index: 1;
}
main::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.9);
}
.box {
  position: relative;
  width: 100%;
  max-width: 1020px;
  height: 640px;
  background-color: #fff;
  border-radius: 3.3rem;
  box-shadow: 0 60px 40px -30px rgba(0, 0, 0, 0.27);
}
.inner-box {
  position: absolute;
  width: calc(100% - 4.1rem);
  height: calc(100% - 4.1rem);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.forms-wrap {
  position: absolute;
  height: 100%;
  width: 45%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  transition: 0.8s ease-in-out;
}
.forms-wrap form {
  max-width: 360px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  transition: opacity 0.02s 0.4s;
}
form.sign-up-form {
  opacity: 0;
  pointer-events: none;
}
form.sign-up-admin {
  opacity: 0;
  pointer-events: none;
}
.logo {
  display: flex;
  align-items: center;
}
.logo img {
  width: 50px;
  height: 50px;
  border-radius: 5%;
  overflow: hidden;
  object-fit: contain;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  margin-right: 0.3rem;
}
.logo h4 {
  color: var(--secondary);
  font-weight: bold;
}
.logo h4::after {
  content: "";
  display: block;
  width: 2.5rem;
  height: 0.3rem;
  background-color: var(--main-color);
  margin: 0 auto;
  position: absolute;
  border-radius: 10px;
}
.heading h2 {
  font-size: 2rem;
  font-weight: 600;
  color: var(--secondary);
}

.heading h6 {
  color: var(--lightgrey);
  font-weight: 400;
  font-size: 1rem;
  display: inline;
}
.toggle {
  color: var(--secondary);
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  transition: 0.3s;
}
.toggle:hover {
  color: var(--main-color);
}
.input-wrap {
  position: relative;
  height: 37px;
  margin-bottom: 2rem;
}

.input-field {
  position: absolute;
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid #bbb;
  padding: 0;
  font-size: 0.95rem;
  color: #151111;
  transition: 0.4s;
}
label {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.95rem;
  color: #bbb;
  pointer-events: none;
  transition: 0.4s;
}
.input-field.active {
  border-bottom-color: #151111;
}

.input-field.active + label {
  font-size: 0.75rem;
  top: -2px;
}
.password-wraps {
  position: absolute;
  right: 10px;
  top: 13px;
  cursor: pointer;
}
.password-wrapper {
  display: flex;
  align-items: center;
}

.password-wrapper input {
  flex-grow: 1;
}

.password-wrapper i {
  cursor: pointer;
  margin-left: -30px;
}

.sign-btn {
  display: inline-block;
  width: 100%;
  height: 45px;
  background-color: var(--secondary);
  color: #fff;
  border: none;
  border-radius: 0.8rem;
  font-size: 1rem;
  margin-bottom: 2rem;
  transition: 0.3s;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}
.sign-btn:hover {
  background-color: var(--main-color);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}
.text a {
  font-size: 15px;
  color: var(--ggec-secondary);
  transition: 0.3s;
  text-decoration: none;
}
.text a:hover {
  color: var(--main-color);
}
main.sign-up-mode form.sign-in-form {
  opacity: 0;
  pointer-events: none;
}

main.sign-up-mode form.sign-up-form {
  opacity: 1;
  pointer-events: all;
}

main.sign-up-mode form.admin-sign-up {
  opacity: 1;
  pointer-events: all;
}

main.sign-up-mode .forms-wrap {
  left: 55%;
}

main.sign-up-mode .carousel {
  left: 0%;
}

.carousel {
  position: absolute;
  height: 100%;
  width: 55%;
  left: 45%;
  top: 0;
  background-color: #f5f5f5;
  border-radius: 2rem;
  display: grid;
  grid-template-rows: auto 1fr;
  padding-bottom: 2rem;
  overflow: hidden;
  transition: 0.8s ease-in-out;
  box-shadow: 0 60px 40px -30px rgba(0, 0, 0, 0.27);
}

.images-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  height: 100%; /* Assurez-vous que le conteneur parent a une hauteur définie */
  margin-top: 60px;
}

.image.show {
  opacity: 1;
  width: 75%;
  transform: none;
  border-radius: 50%;
  box-shadow: 0 60px 40px -30px rgba(0, 0, 0, 0.27);
  justify-self: center; /* Centrer horizontalement */
  align-self: center; /* Centrer verticalement */
}

.img-1 {
  transform: translate(0, -50px);
}

.img-2 {
  transform: scale(0.4, 0.5);
}

.img-3 {
  transform: scale(0.3) rotate(-20deg);
}

.image.show {
  opacity: 1;
  width: 75%;
  transform: none;
  border-radius: 50%;
  box-shadow: 0 60px 40px -30px rgba(0, 0, 0, 0.27);
  justify-self: center; /* Centrer horizontalement */
  align-self: center; /* Centrer verticalement */
}

.text-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.text-wrap {
  max-height: 2.2rem;
  overflow: hidden;
  margin-bottom: 2.5rem;
}

.text-group {
  display: flex;
  flex-direction: column;
  text-align: center;
  transform: translateY(0);
  transition: 0.5s;
}

.text-group h2 {
  line-height: 2.2rem;
  font-weight: 600;
  font-size: 1.6rem;
}

.bullets {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bullets span {
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #aaa;
  margin: 0 0.25rem;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
}

.bullets span.active {
  width: 1.1rem;
  background-color: #151111;
  border-radius: 1rem;
}

@media (max-width: 850px) {
  .box {
    height: auto;
    max-width: 550px;
    overflow: hidden;
  }

  .inner-box {
    position: static;
    transform: none;
    width: revert;
    height: revert;
    padding: 2rem;
  }

  .forms-wrap {
    position: revert;
    width: 100%;
    height: auto;
  }

  form {
    max-width: revert;
    padding: 1.5rem 2.5rem 2rem;
    transition: transform 0.8s ease-in-out, opacity 0.45s linear;
  }

  .heading {
    margin: 2rem 0;
  }

  form.sign-up-form {
    transform: translateX(100%);
  }

  main.sign-up-mode form.sign-in-form {
    transform: translateX(-100%);
  }

  main.sign-up-mode form.sign-up-form {
    transform: translateX(0%);
  }

  .carousel {
    position: revert;
    height: auto;
    width: 100%;
    padding: 3rem 2rem;
    display: flex;
  }

  .images-wrapper {
    display: none;
  }

  .text-slider {
    width: 100%;
  }
}

@media (max-width: 530px) {
  main {
    padding: 1rem;
  }

  .box {
    border-radius: 2rem;
  }

  .inner-box {
    padding: 1rem;
  }

  .carousel {
    padding: 1.5rem 1rem;
    border-radius: 1.6rem;
  }

  .text-wrap {
    margin-bottom: 1rem;
  }

  .text-group h2 {
    font-size: 1.2rem;
  }

  form {
    padding: 1rem 2rem 1.5rem;
  }
}
