/* =========== Google Fonts ============ */
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");

:root {
  /* Primary colors */
  --text-color: hsl(213, 96%, 18%);
  --purplish-blue: hsl(243, 100%, 62%);
  --pastel-blue: hsl(228, 100%, 84%);
  --light-blue: hsl(206, 94%, 87%);
  --strawberry-red: hsl(354, 84%, 57%);
  /* Neutral colors */
  --cool-gray: hsl(231, 11%, 63%);
  --light-gray: hsl(229, 24%, 87%);
  --magnolia: hsl(217, 100%, 97%);
  --alabaster: hsl(231, 100%, 99%);
  --white: hsl(0, 0%, 100%);

  /* #4800ff in decimal RGB */
  --secondary: rgb(49, 0, 174);
  /* #ff0000 in decimal RGB */
  --main-color: rgb(255, 0, 0);
  /* #ffffff in decimal RGB */
  --white: rgb(255, 255, 255);
  /* #555555 in decimal RGB */
  --grey: rgb(85, 85, 85);
  /* #959595 in decimal RGB */
  --lightgrey: rgb(149, 149, 149);
  /* #222222 in decimal RGB */
  --darkgrey: rgb(34, 34, 34);
  /* #000000 in decimal RGB */
  --black: rgb(0, 0, 0);
  /* #fdfdfc in decimal RGB */
  --light: rgb(253, 253, 252);
  /* #1f9fd4 in decimal RGB */
  --light-secon--secondary: rgb(31, 59, 212);
  /* #ffcc00 in decimal RGB */
  --yellow: rgb(255, 204, 0);
  /* #f5f5f5 in decimal RGB */
  --bg-grey: rgb(245, 245, 245);
  /* #4661c5 in decimal RGB */
  --facebook-color: rgb(70, 97, 197);
  /* #44b1e4 in decimal RGB */
  --twitter-color: rgb(68, 177, 228);
  /* #0029c2 in decimal RGB */
  --linkedin-color: rgb(0, 41, 194);

  /* #ad0001 in decimal RGB */
  --red: rgb(173, 0, 1);
  /* #033e00 in decimal RGB */
  --green: rgb(3, 62, 0);
  /* #0ec312 in decimal RGB */
  --light-green: rgb(14, 195, 18);
  /* #0043ff in decimal RGB */
  --sl-secon--secondary: rgb(0, 67, 255);
  /* #00118e in decimal RGB */
  --dl-secon--secondary: rgb(0, 17, 142);
  /* #3f003b in decimal RGB */
  --purple: rgb(63, 0, 59);
  /* #be00c5 in decimal RGB */
  --light-purple: rgb(190, 0, 197);
  --hRed: #f52c2c;

  --main-color-opicity: 255, 0, 0;
  --secondary-opicity: 49, 0, 174;
  --white-opicity: 255, 255, 255;
  --black-opicity: 0, 0, 0;
  --sideColor: #ebf6ff;
  --primary-bg: #171717;
  --secondary-bg: #262626;
  --accent-bg: #4f46e5;

  --primary-color: #fff;
  --secondary-color: rgba(255, 255, 255, 70%);
  --accent-color: #818cf8;

  --border-color: rgba(255, 255, 255, 30%);

  --username-size: 32px;
  --title-size: 18px;
  --subtitle: 18px;

  --calendar-main-color: #1a8fff;
  --calendar-text-color: #777;
  --calendar-text-color-light: #ccc;
  --calendar-border-color: #eee;
  --calendar-bg-color: #f9f9f9;
  --calendar-neutral-color: #fff;

  --dark-bg: #383838;
  --main-color-alt: #faa650;
  --text-color: #707070;
  --white-color: #ffffff;
  --white-alt: #e6e6e6;

  --body-color: #e4e9f7;
  --sidebar-color: #fff;
  --sideColor: #ebf6ff;
  --primary-color: #695cfe;
  --primary-color-light: #f6f5ff;
  --toggle-color: #ddd;
  --text-color: #707070;
  --secondary: rgb(49, 0, 174);
  /* #ff0000 in decimal RGB */
  --main-color: rgb(255, 0, 0);
  /* #ffffff in decimal RGB */
  --white: rgb(255, 255, 255);
  /* #555555 in decimal RGB */
  --grey: rgb(85, 85, 85);
  /* #959595 in decimal RGB */
  --lightgrey: rgb(149, 149, 149);
  /* #222222 in decimal RGB */
  --darkgrey: rgb(34, 34, 34);
  /* #000000 in decimal RGB */
  --black: rgb(0, 0, 0);
  /* #fdfdfc in decimal RGB */
  --light: rgb(253, 253, 252);
  /* #1f9fd4 in decimal RGB */
  --light-blue: rgb(31, 59, 212);
  /* #ffcc00 in decimal RGB */
  --yellow: rgb(255, 204, 0);
  /* #f5f5f5 in decimal RGB */
  --bg-grey: rgb(245, 245, 245);
  /* #4661c5 in decimal RGB */
  --facebook-color: rgb(70, 97, 197);
  /* #44b1e4 in decimal RGB */
  --twitter-color: rgb(68, 177, 228);
  /* #0029c2 in decimal RGB */
  --linkedin-color: rgb(0, 41, 194);

  /* #ad0001 in decimal RGB */
  --red: rgb(173, 0, 1);
  /* #033e00 in decimal RGB */
  --green: rgb(3, 62, 0);
  /* #0ec312 in decimal RGB */
  --light-green: rgb(14, 195, 18);
  /* #0043ff in decimal RGB */
  --sl-blue: rgb(0, 67, 255);
  /* #00118e in decimal RGB */
  --dl-blue: rgb(0, 17, 142);
  /* #3f003b in decimal RGB */
  --purple: rgb(63, 0, 59);
  /* #be00c5 in decimal RGB */
  --light-purple: rgb(190, 0, 197);

  /* Primary colors */
  --marine-blue: hsl(213, 96%, 18%);
  --purplish-blue: hsl(243, 100%, 62%);
  --pastel-blue: hsl(228, 100%, 84%);
  --light-blue: hsl(206, 94%, 87%);
  --strawberry-red: hsl(354, 84%, 57%);
  /* Neutral colors */
  --cool-gray: hsl(231, 11%, 63%);
  --light-gray: hsl(229, 24%, 87%);
  --magnolia: hsl(217, 100%, 97%);
  --alabaster: hsl(231, 100%, 99%);
  --white: hsl(0, 0%, 100%);

  --main-color-opicity: 255, 0, 0;
  --secondary-opicity: 49, 0, 174;
  --white-opicity: 255, 255, 255;
  --black-opicity: 0, 0, 0;
  --sideColor: #ebf6ff;
  --primary-bg: #171717;
  --secondary-bg: #262626;
  --accent-bg: #4f46e5;

  --trans-02: all 0.2s ease;
  --trans-03: all 0.2s ease;
  --trans-04: all 0.2s ease;
  --trans-05: all 0.2s ease;

  /*============ FONT AND TYPOGRAPHY =============*/
  --body-font: "Poppins", sans-serif;
  --biggest-font-size: 2.25rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813;
  --smaller-font-size: 0.75rem;

  /*============ FONT WIDTH =============*/
  --font-medium: 500;
  --font-semi-bold: 600;

  /*============ MARGIN PADDING =============*/
  --mb-1: 0.5rem;
  --mb-2: 1rem;
  --mb-3: 1.5rem;
  --mb-4: 2rem;
  --mb-5: 2.5rem;
  --mb-6: 3rem;

  /*============ Z INDEX =============*/
  --z-tooltip: 10;
  --z-fixed: 100;

  /*============ Phone Input =============*/
  --PhoneInput-color--focus: #03b2cb;
  --PhoneInputInternationalIconPhone-opacity: 0.8;
  --PhoneInputInternationalIconGlobe-opacity: 0.65;
  --PhoneInputCountrySelect-marginRight: 0.3em;
  --PhoneInputCountrySelectArrow-width: 0.3em;
  --PhoneInputCountrySelectArrow-marginLeft: var(
    --PhoneInputCountrySelect-marginRight
  );
  --PhoneInputCountrySelectArrow-borderWidth: 1px;
  --PhoneInputCountrySelectArrow-opacity: 0.45;
  --PhoneInputCountrySelectArrow-color: currentColor;
  --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountrySelectArrow-transform: rotate(45deg);
  --PhoneInputCountryFlag-aspectRatio: 1.5;
  --PhoneInputCountryFlag-height: 1em;
  --PhoneInputCountryFlag-borderWidth: 1px;
  --PhoneInputCountryFlag-borderColor: rgba(0, 0, 0, 0.5);
  --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, 0.1);
}

.registerBody {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../images/ray_taxi.webp") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  position: relative;
  z-index: 1;
}
.registerBody::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.9);
}
.registerContainer {
  width: 80%;
  height: 80vh;
  display: flex;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.212);
  background: var(--white);
}
.form-image {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  padding: 1rem;
  box-shadow: 0 60px 40px -30px rgba(0, 0, 0, 0.27);
}
.form-image::before {
  content: "";
  position: absolute;
  top: 10%;
  left: 10%;
  width: 40%;
  height: 80%;
  background: linear-gradient(225deg, #0043ff, #be00c5);
  z-index: 1;
  mix-blend-mode: screen;
}
.form-image img {
  position: absolute;
  width: 31rem;
  border-radius: 5%;
  object-fit: cover;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.212);
}
.container {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;
  padding: 1rem;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}
.form-header {
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.formTitle {
  font-size: 2rem;
  font-weight: 700;
  color: var(--marine-blue);
  margin-bottom: 0.5rem;
  order: 1; /* Ajouté pour mettre le titre à gauche */
}
.formInfo {
  color: var(--cool-gray);
  font-size: 1rem;
  margin-bottom: 0.5rem;
}
.login-button {
  display: flex;
  align-items: center;
}
.login-button img {
  width: 50px;
  height: 50px;
  border-radius: 5%;
  overflow: hidden;
  object-fit: contain;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  order: 2; /* Ajouté pour mettre l'image à droite */
}
.form-header {
  width: 100%;
  padding: 25px 30px;
}
.form-header h1 {
  color: var(--secondary);
  font-weight: bold;
}
.form-header h1::after {
  content: "";
  display: block;
  width: 8rem;
  height: 0.3rem;
  background-color: var(--main-color);
  margin: 0 auto;
  position: absolute;
  border-radius: 10px;
}
.form-container {
  max-width: 700px;
  width: 100%;
  padding: 5px 30px;
  border-radius: 5px;
}
.form-container form .user-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0 12px 0;
}
form .user-details .input-box {
  width: calc(100% / 2 - 20px);
}
.user-details .input-box .details {
  display: block;
  font-weight: 500;
  margin-bottom: 10px;
}
.user-details .input-box input {
  height: 45px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding-left: 15px;
  font-size: 16px;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
}
.user-details .input-box select {
  height: 45px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding-left: 15px;
  font-size: 16px;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
}
.user-details .input-box input:focus,
.user-details .input-box input:valid {
  border-color: #9b59b6;
}
.PhoneInput input {
  height: 45px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding-left: 15px;
  font-size: 16px;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
}
.PhoneInput input::placeregisters {
  color: var(--text-color);
}
.PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: var(--PhoneInputCountrySelect-marginRight);
}
.PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
}
.PhoneInputCountryIcon--border {
  background-color: none;
  box-shadow: none;
}
.PhoneInputCountryIcon {
  width: calc(
    var(--PhoneInputCountryFlag-height) *
      var(--PhoneInputCountryFlag-aspectRatio)
  );
  height: var(--PhoneInputCountryFlag-height);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}
.input-box {
  position: relative;
  /* autres styles */
}

.input-box input {
  padding-right: 40px; /* espace pour l'icône */
  /* autres styles */
}

.input-box i {
  position: absolute;
  top: 70%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.flexParent,
.sign-in {
  flex-direction: row;
  justify-content: space-between;
}
.flexParent {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  flex: 1;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  align-items: flex-end;
}
.button {
  padding: 0.75rem 2.5rem;
  border: 1px solid transparent;
  background-color: var(--secondary);
  border-radius: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  font-size: 1rem;
  color: var(--alabaster);
  cursor: pointer;
  margin-bottom: 15px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}
.button:hover,
.button:focus {
  background-color: #365e91;
}
.sign-in {
  position: relative;
  bottom: -30px; /* Ajustez cette valeur selon vos besoins */
  font-size: 15px;
  text-align: center;
  color: var(--text-color);
  text-transform: lowercase;
  margin-bottom: 15px;
}
.sign-in a {
  text-decoration: none;
  color: var(--secondary);
  text-transform: uppercase;
}

.sign-in a:hover {
  text-decoration: none;
  color: var(--main-color);
  font-weight: bold;
}

/* ===============================Register popup==================================*/
.popup-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
}

.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 550px;
  height: 500px;
  background: #f5f5f5;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  z-index: 2;
}

.popup-close {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 40px;
  height: 40px;
  background: #555;
  color: #f5f5f5;
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
}

.popup-form .avatar {
  margin: 30px 0px 20px;
  text-align: center;
}

.popup-form .avatar img {
  width: 130px;
  border-radius: 50%;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.212);
}

.popup-form .header {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: var(--secondary);
  margin: 20px 0px;
}

.form-element {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0 12px 0;
}

.form-element .element {
  width: calc(50% - 10px);
  padding: 8px 15px;
}

.form-element .element label {
  display: block;
  font-weight: 500;
  margin-bottom: 10px;
}

.form-element .element input,
.form-element .element select {
  height: 45px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding-left: 15px;
  font-size: 16px;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
}

.form-element .element input:focus,
.form-element .element input:valid {
  border-color: #9b59b6;
}
.flex-parent {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex: 1;
  padding: 25px 15px;
}

.button-wrapper {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  align-items: flex-end;
}
.element-button {
  padding: 0.75rem 2.5rem;
  border: 1px solid transparent;
  background-color: var(--secondary);
  border-radius: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  font-size: 1rem;
  color: var(--alabaster);
  cursor: pointer;
  margin-bottom: 15px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}
.button:hover,
.button:focus {
  background-color: var(--light-purple);
}

@media (max-width: 991px) {
  .registerContainer {
    max-width: 400px;
  }

  section .container .imgBx {
    display: none;
  }

  section .container .user .formBx {
    width: 100%;
  }
}