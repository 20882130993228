:root {
  /* #4800ff in decimal RGB */
  --secondary: rgb(49, 0, 174);
  /* #ff0000 in decimal RGB */
  --main-color: rgb(255, 0, 0);
  /* #ffffff in decimal RGB */
  --white: rgb(255, 255, 255);
  /* #555555 in decimal RGB */
  --grey: rgb(85, 85, 85);
  /* #959595 in decimal RGB */
  --lightgrey: rgb(149, 149, 149);
  /* #222222 in decimal RGB */
  --darkgrey: rgb(34, 34, 34);
  /* #000000 in decimal RGB */
  --black: rgb(0, 0, 0);
  /* #fdfdfc in decimal RGB */
  --light: rgb(253, 253, 252);
  /* #1f9fd4 in decimal RGB */
  --light-secon--secondary: rgb(31, 59, 212);
  /* #ffcc00 in decimal RGB */
  --yellow: rgb(255, 204, 0);
  /* #f5f5f5 in decimal RGB */
  --bg-grey: rgb(245, 245, 245);
  /* #4661c5 in decimal RGB */
  --facebook-color: rgb(70, 97, 197);
  /* #44b1e4 in decimal RGB */
  --twitter-color: rgb(68, 177, 228);
  /* #0029c2 in decimal RGB */
  --linkedin-color: rgb(0, 41, 194);

  /* #ad0001 in decimal RGB */
  --red: rgb(173, 0, 1);
  /* #033e00 in decimal RGB */
  --green: rgb(3, 62, 0);
  /* #0ec312 in decimal RGB */
  --light-green: rgb(14, 195, 18);
  /* #0043ff in decimal RGB */
  --sl-secon--secondary: rgb(0, 67, 255);
  /* #00118e in decimal RGB */
  --dl-secon--secondary: rgb(0, 17, 142);
  /* #3f003b in decimal RGB */
  --purple: rgb(63, 0, 59);
  /* #be00c5 in decimal RGB */
  --light-purple: rgb(190, 0, 197);
  --hRed: #f52c2c;

  --main-color-opicity: 255, 0, 0;
  --secondary-opicity: 49, 0, 174;
  --white-opicity: 255, 255, 255;
  --black-opicity: 0, 0, 0;
  --sideColor: #ebf6ff;
  --primary-bg: #171717;
  --secondary-bg: #262626;
  --accent-bg: #4f46e5;

  --primary-color: #fff;
  --secondary-color: rgba(255, 255, 255, 70%);
  --accent-color: #818cf8;

  --border-color: rgba(255, 255, 255, 30%);

  --username-size: 32px;
  --title-size: 18px;
  --subtitle: 18px;

  --calendar-main-color: #1a8fff;
  --calendar-text-color: #777;
  --calendar-text-color-light: #ccc;
  --calendar-border-color: #eee;
  --calendar-bg-color: #f9f9f9;
  --calendar-neutral-color: #fff;
}
/* Ajoutez ce style à votre feuille de style CSS */
.appContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center; /* Centrer l'image horizontalement */
  margin-top: -50px; /* Fait monter l'image de 50px */
  background: #ffffff;
  border-radius: 50%;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  width: 100px; /* Largeur de l'élément */
  height: 100px; /* Hauteur de l'élément */
}

.imageContainer img {
  width: 80px; /* Largeur de l'image */
  height: auto; /* Hauteur de l'image */
  overflow: hidden;
  object-fit: contain;
  border: none;
  border-radius: 50%;
}

.spinnerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25%;
}

.spinner {
  width: 56px;
  height: 56px;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-right-color: var(--secondary);
  animation: tri-spinner 1s infinite linear;
}

.spinner::before,
  .spinner::after {
    content: "";
    grid-area: 1/1;
    margin: 2px;
    border: inherit;
    border-radius: 50%;
    animation: tri-spinner 2s infinite;
  }

  .spinner::after {
    margin: 8px;
    animation-duration: 3s;
  }

  @keyframes tri-spinner {
    100% {
      transform: rotate(1turn);
    }
  }
  
  .loader {
    color: var(--secondary);
    font-family: "Poppins",sans-serif;
    font-weight: bold;
    font-size: 25px;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 40px;
    padding: 10px 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 8px;
  }

  .words {
    overflow: hidden;
  }

  .word {
    display: block;
    height: 100%;
    padding-left: 6px;
    color: var(--main-color);
    animation: cycle-words 5s infinite;
  }

  @keyframes cycle-words {
    10% {
      -webkit-transform: translateY(-105%);
      transform: translateY(-105%);
    }
  
    25% {
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
    }
  
    35% {
      -webkit-transform: translateY(-205%);
      transform: translateY(-205%);
    }
  
    50% {
      -webkit-transform: translateY(-200%);
      transform: translateY(-200%);
    }
  
    60% {
      -webkit-transform: translateY(-305%);
      transform: translateY(-305%);
    }
  
    75% {
      -webkit-transform: translateY(-300%);
      transform: translateY(-300%);
    }
  
    85% {
      -webkit-transform: translateY(-405%);
      transform: translateY(-405%);
    }
  
    100% {
      -webkit-transform: translateY(-400%);
      transform: translateY(-400%);
    }
  }