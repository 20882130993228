.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 175vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}